import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Components/LoadingSpinner";
import { useUser } from "../Components/UserContext.jsx";

const PostLoginRouter = (props) => {
  const { user, loading, getUserData } = useUser();
  const [hasFetched, setHasFetched] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    props.handleIsLogInScreen(true);
    const fetch = async () => {
      await getUserData(); // wait for user data
      setHasFetched(true); // now we know it's fresh
    };
    fetch();
  }, []);


  useEffect(() => {
    if (!hasFetched || loading || !user) return;

    console.log(user);
    if (!user.org.orgSetup) {
      navigate("/setup/org");
    } else if (!user.completedOnboarding) {
      navigate("/setup/user");
    } else{
        props.handleIsLogInScreen(false);
        navigate("/");
    } 
  }, [user, loading, navigate]);


  return (
    <div style={{ height: "100vh" }}>
      <LoadingSpinner />
    </div>
  ); // or a loading spinner
};

export default PostLoginRouter;
