import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./League.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Modal from "react-modal";
import ReactPlayer from "react-player/lazy";

export default function LeagueTeamView(props) {
  const [copySuccess, setCopySuccess] = useState(false);
  const [data, setData] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [startModalOpen, setStartModal] = useState(false);
  const [teamRows, setTeamRows] = useState(false);
  const [modalError, setModalError] = useState(false);

  const location = useLocation();
  const toggleModal = () => {
    setVideoModalOpen(!videoModalOpen);
  };

  const toggleStartModal = () => {
    setStartModal(!startModalOpen);
  };

  const removePlayer = async (email) => {
  

      const pathParts = location.pathname.split("/");
      const lastPathSegment = pathParts[pathParts.length - 1];

      const res = await axios.post("/leagueAPI/removePlayer", {
        id: lastPathSegment,
        player:email
      });
      if (res.data.done) {
       
            getLeagueData();
      }
    
 
  };

  const startLeague = async () => {
    try {
      const pathParts = location.pathname.split("/");
      const lastPathSegment = pathParts[pathParts.length - 1];

      const res = await axios.post("/leagueAPI/startLeague", {
        id: lastPathSegment,
      });
      if (res.data) {
        //setData(res.data)
        toggleStartModal();
        getLeagueData();
      }
    } catch (error) {
      console.log(error)
      console.log("Server error. Refresh and try again.");
      setModalError(error?.response?.data?.message)
    }
  };


  const getLeagueData = async () => {

      const pathParts = location.pathname.split("/");
      const lastPathSegment = pathParts[pathParts.length - 1];

      const res = await axios.post("/leagueAPI/getLeague", {
        id: lastPathSegment,
      });
   
      if (res.data && props.user) {
        
        var localIsAdmin=false;

        if (props.user.email === res.data.admin){
            setIsAdmin(true);
            localIsAdmin=true;
        } 
        
        const updatedTeams = res.data.teams.map((team) => {
            return {
              ...team,
              members: (team.members || []).map((member) => {
                const showDelete = (member.email === props.user.email) || localIsAdmin;
                return {
                  ...member,
                  showDelete,
                };
              }),
            };
          });

          
          setTeamRows(updatedTeams);
          setData(res.data);
      }
 
 
  };

  const joinTeam = async (teamNumber) => {
    try {
      const pathParts = location.pathname.split("/");
      const lastPathSegment = pathParts[pathParts.length - 1];

      const res = await axios.post("/leagueAPI/joinTeam", {
        id: lastPathSegment,
        team: teamNumber,
      });
      if (res.data.done) {
        getLeagueData();
      }
    } catch (error) {
      console.log("Server error. Refresh and try again.");
    }
  };


  useEffect(() => {
    getLeagueData();
  }, [props.user]);

  return (
    <>
      <Modal
        isOpen={videoModalOpen}
        className="video-modal"
        overlayClassName="custom-overlay"
        onRequestClose={() => toggleModal()}
      >
        <ReactPlayer
          controls={true}
          volume={1}
          url="https://happmatch-images.s3.us-east-2.amazonaws.com/move_trailer.mp4"
        />
      </Modal>
      <Modal
        isOpen={startModalOpen}
        className="custom-modal"
        overlayClassName="custom-overlay"
        onRequestClose={() => toggleStartModal()}
      >
        <h2>Ready to start?</h2>
        Once you start the league no other members can join. Everyone is the
        league will recieve an email from Moove within 24 hours!
        <br />
        <div className="modal-ctas">
          <button
            className="button-9"
            onClick={() => {
              startLeague();
            }}
          >
            Start
          </button>
          <div className="modal-close-cta" onClick={() => toggleStartModal()}>
            {" "}
            Cancel
          </div>
          {modalError && <div className='error'>{modalError}</div>}
        </div>
      </Modal>
      
      {teamRows && props.user && (
        <div className="module-container">
          <div
        onClick={() => {
          window.location.href = "/leagues";
        }}
        className="back-carrot"
      >
        &#x3c; Back<br/><br/>
      </div>
          <div className="module-header-with-cta">
            <div className="module-header header-with-cta">
              <div className="module-title">
                <u>{data.name}</u>
              </div>
              <div className="module-subtitle">Admin: {data.admin}</div>
            </div>
            <div className="module-header-cta">
              <div className="copy-cta">
                <CopyToClipboard
                  text={
                    "https://app.happmatch.com/leagues/" + data.id + "?s=inv"
                  }
                  onCopy={() => setCopySuccess(true)}
                >
                  <button className="copy-to-clip-board-btn">
                    <svg
                      fill="#ccc"
                      height="15px"
                      width="15px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      enable-background="new 0 0 64 64"
                    >
                      <g id="Text-files">
                        <path
                          d="M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228
		C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999
		c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64
		h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002
		C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228
		c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999
		c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z
		 M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699
		c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946
		c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999
		h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z"
                        />
                        <path
                          d="M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005
		c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997
		C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z"
                        />
                        <path
                          d="M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986
		c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016
		C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z"
                        />
                        <path
                          d="M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
		s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997
		S39.16465,29.4603004,38.6031494,29.4603004z"
                        />
                        <path
                          d="M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
		s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997
		S29.0059509,37.5872993,28.4444485,37.5872993z"
                        />
                      </g>
                    </svg>
                    {copySuccess ? "Copied!" : "Copy invite URL"}
                  </button>
                </CopyToClipboard>
              </div>
              {isAdmin && data.status === "Open" && (
                <button
                  onClick={() => toggleStartModal()}
                  className="button-9 no-margin"
                >
                  Start
                </button>
              )}
              {isAdmin && data.status != "Open" && (
                <button disabled className="button-9 no-margin">
                  Running
                </button>
              )}
            </div>
          </div>
          <img
            onClick={() => toggleModal()}
            style={{
              cursor: "pointer",
              width: "100%",
              margin: "10px",
              border: "1px solid lightgray",
              borderRadius: "8px",
            }}
            alt='how to play instructions'
            src="/images/moove_banner.png"
          />

          <div className="top-level-content">
            <div className="top-level-group">
              <div className="top-level-data">{data.app}</div>{" "}
              <div className="top-level-label">App</div>
            </div>
            <div className="top-level-group">
              <div className="top-level-data">{data.number_teams}</div>
              <div className="top-level-label">Number teams</div>
            </div>
            <div className="top-level-group">
              <div className="top-level-data green">{data.status}</div>{" "}
              <div className="top-level-label">Status</div>
            </div>
          </div>
          <div className="league-teams">
            {teamRows.map((ele, i) => {
              return (
                <div className="league-team-view">
                  <div className="league-team-view-header">
                    <div className="league-team-view-name">{ele.name}</div>
                    <div className="league-team-view-join">
                      {data.status === "Open" && (
                        <button
                          onClick={() => joinTeam(i)}
                          className="button-9-small-green"
                        >
                          Join
                        </button>
                      )}
                    </div>
                  </div>
                {ele.members.map((item,i)=>{
                    return <div className='team-line-item'>
                        <div className='team-line-item-email'>
                        {i+1}. {item.email}
                        </div>
                        {(isAdmin || item.email===props.user.email) &&
                        <div className="team-line-item-delete" onClick={()=>removePlayer(item.email)} style={{ cursor: "pointer" }}>
                    <img width="20px" src="/icons/trashcan.svg" alt="Delete" />
                  </div>}

                    </div>
                    
                    

                })}
                 
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
