import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingSpinner from "./Components/LoadingSpinner";
import axios from "axios";
import PrivateRoute from './Components/PrivateRoute'; // The component you just created
import './App.css';
import {isMobile} from 'react-device-detect';
import SideNav from "./Components/SideNav.jsx";
import mixpanel from 'mixpanel-browser';
import LeagueTeamView from "./Containers/LeagueTeamView.jsx";
import Footer from "./Components/Footer.jsx";
import PostLoginRouter from "./Containers/PostLoginRouter.jsx";
import { useUser } from './Components/UserContext.jsx';

//CONTAINERS
const NewHeader = React.lazy(() => import("./Components/NewHeader.jsx"));
const AppOwner = React.lazy(() => import("./Containers/AppOwner/AppOwner.jsx"));
const InviteModal = React.lazy(() => import("./Components/InviteModal.jsx"));
const PageNotFound = React.lazy(() => import("./Containers/PageNotFound.jsx"));
const SurveyRecorder = React.lazy(() => import("./Containers/SurveyRecorder.jsx"));
const Explore = React.lazy(() => import("./Containers/Explore.jsx"));
const ToolProfile = React.lazy(() => import("./Containers/ToolProfile.jsx"));
const UserSetup = React.lazy(() => import("./Containers/Setup/UserSetup.jsx"));
const OrgSetup = React.lazy(() => import("./Containers/Setup/OrgSetup.jsx"));

const LoginPage = React.lazy(() => import("./Containers/LoginPage.jsx"));
const Profile = React.lazy(() => import("./Containers/Profile.jsx"));
const Admin = React.lazy(() => import("./Containers/Admin/Admin.jsx"));
const OrgSettings = React.lazy(() => import("./Containers/Admin/OrgSettings"));
const Employees = React.lazy(() => import("./Containers/Admin/Employees.jsx"));
const Utilization = React.lazy(() => import("./Containers/Admin/Utilization.jsx"));
const ContentHub = React.lazy(() => import("./Containers/Admin/ContentHub.jsx"));
const GetAppModal = React.lazy(() => import("./Components/GetAppModal.jsx"));
const LaunchHub = React.lazy(() => import("./Containers/Admin/LaunchHub.jsx"));

const Users = React.lazy(() => import("./Containers/AppOwner/Users.jsx"));
const Analytics = React.lazy(() => import("./Containers/AppOwner/Analytics.jsx"));
const ManageAdmins = React.lazy(() => import("./Containers/AppOwner/ManageAdmins.jsx"));
const Performance = React.lazy(() => import("./Containers/AppOwner/Performance.jsx"));
const AppOwnerProfile = React.lazy(() => import("./Containers/AppOwner/AppOwnerProfile.jsx"));
const AssessmentWrapper = React.lazy(() => import("./Containers/AssessmentWrapper.jsx"));
const GlobalSubscriptions = React.lazy(() => import("./Containers/GlobalAdmin/GlobalSubscriptions.jsx"));
const LeagueApps = React.lazy(() => import("./Containers/LeagueApps.jsx"));

function App() {

  const { getUserData } = useUser();

  mixpanel.init("393f475d972dd9138f742a49ff5cfe53", {
    debug: false,
    ignore_dnt: true,
    track_pageview: 'url-with-path',
    persistence: "localStorage",
  });
  const [subscribedApp,setSubscribedApp] = useState(false);
  const [showGetAppModal,setShowGetAppModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [isSideNavCollapsed,setIsSideNavCollapsed] = useState();
  const [user,setUser] = useState();
  const [isLoggedIn,setIsLoggedIn] = useState();
  const [isLogInScreen,setIsLogInScreen] = useState();
  const [happbotSetup, setIsHappbotSetup] = useState(true);
  
const toggleInviteModal = () =>{
  setShowInviteModal(!showInviteModal)
}

const toggleGetAppModal = (app) =>{
  setSubscribedApp(app);
  setShowGetAppModal(!showGetAppModal);
 }

 const handleIsLogInScreen = (result) =>{
  setIsLogInScreen(result);
 }

 const toggleSideNav = () =>{
  if(isMobile)
  setIsSideNavCollapsed(!isSideNavCollapsed)
 }


const getUserDataOld = async () => {
  try {
    const res = await axios.get("/user");

    if (res.data && res.data.email) {
  

    if(!res.data.public_test_account)
      mixpanel.register({
        'Email': res.data.email,
        'isAdmin': res.data.isAdmin,
        'orgSubscribed': res.data.org.subscribed,
        'isAppOwner': res.data.appOwner,
        'domain':res.data.domain
    });

    if(res.data){
      localStorage.setItem('hasEverLoggedIn', true);
    }
  
      setUser(res.data);
      localStorage.setItem('isLoggedIn', true);
      setIsLoggedIn(true);

      if (res.data.isAdmin) {
      

        if(!res.data.org.slack_enabled)
          setIsHappbotSetup(false)
      }
    } else if (res.status === 401) {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    } else {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    } else {
      console.log(error)
      console.log("Server error. Refresh.");
    }
  }
};
 
 useEffect(() => {
   if(isMobile)
      setIsSideNavCollapsed(true);

   getUserDataOld();
   getUserData();
  
  }, []);

      
  return (
    <div className={isLogInScreen ? ("App no-scroll"):("App")}>

      <Suspense fallback={<LoadingSpinner />}>
          <Router>
            <InviteModal showInviteModal={showInviteModal} toggleInviteModal={toggleInviteModal} user={user}/>
         {isMobile && <SideNav isMobile={isMobile}  toggleSideNav={toggleSideNav} isSideNavCollapsed={isSideNavCollapsed} user={user}/>}

            <GetAppModal user={user} subscribedApp={subscribedApp} showGetAppModal={showGetAppModal} toggleGetAppModal={toggleGetAppModal} />
         {!isLogInScreen && <NewHeader toggleInviteModal={toggleInviteModal} toggleSideNav={toggleSideNav}  isMobile={isMobile} user={user}></NewHeader>}

            <div className='page-container'>          
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path="/login" element={ <LoginPage handleIsLogInScreen={handleIsLogInScreen} user={user} isLoggedIn={isLoggedIn} isLogInScreen={isLogInScreen}  />} />
              <Route path="/survey/*" element={<SurveyRecorder/>} />

              {/* PRIVATE ROUTES */}
              <Route path="/leagues" element={<PrivateRoute><LeagueApps user={user} /></PrivateRoute>} />
              <Route path="/leagues/*" element={<PrivateRoute><LeagueTeamView user={user} /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile user={user}  /></PrivateRoute>} />
              <Route path="/app/*" element={<PrivateRoute><ToolProfile isInDrawer={false} user={user} toggleGetAppModal={toggleGetAppModal} /></PrivateRoute>} />
              <Route path="/admin" element={<PrivateRoute><Admin  user={user}/></PrivateRoute>} />
              <Route path="/admin/settings" element={<PrivateRoute><OrgSettings  user={user}/></PrivateRoute>} />
              <Route path="/admin/utilization" element={<PrivateRoute><Utilization user={user} /></PrivateRoute>} />
              <Route path="/admin/manageadmins" element={<PrivateRoute><ManageAdmins user={user} /></PrivateRoute>} />
              <Route path="/admin/launch" element={<PrivateRoute><LaunchHub user={user} /></PrivateRoute>} />

              <Route path="/admin/employees" element={<PrivateRoute><Employees user={user} /></PrivateRoute>} />
              <Route path="/admin/contenthub" element={<PrivateRoute><ContentHub user={user} /></PrivateRoute>} />
              <Route path="/owner/" element={<PrivateRoute><AppOwner user={user}/></PrivateRoute>} />
              <Route path="/owner/insights" element={<PrivateRoute><Analytics user={user} /></PrivateRoute>} />
              <Route path="/owner/profile" element={<PrivateRoute><AppOwnerProfile user={user}/></PrivateRoute>} />
              <Route path="/owner/users" element={<PrivateRoute><Users user={user} /></PrivateRoute>} />
              <Route path="/owner/settings" element={<PrivateRoute><Performance user={user} /></PrivateRoute>} />
              <Route path="/router" element={<PrivateRoute><PostLoginRouter isMobile={isMobile} handleIsLogInScreen={handleIsLogInScreen} /></PrivateRoute>} />
              <Route path="/setup/org" element={<PrivateRoute><OrgSetup isMobile={isMobile} handleIsLogInScreen={handleIsLogInScreen} /></PrivateRoute>} />
              <Route path="/setup/user" element={<PrivateRoute><UserSetup isMobile={isMobile} handleIsLogInScreen={handleIsLogInScreen} /></PrivateRoute>} />

             
              <Route path="/match" element={<PrivateRoute><AssessmentWrapper isMobile={isMobile} handleIsLogInScreen={handleIsLogInScreen} toggleGetAppModal={toggleGetAppModal} user={user} /></PrivateRoute>} />
              <Route path="/globaladmin/subscriptions" element={<PrivateRoute><GlobalSubscriptions  isMobile={isMobile} user={user} /></PrivateRoute>} />
              <Route path="/" element={<PrivateRoute><Explore toggleGetAppModal={toggleGetAppModal} isMobile={isMobile} user={user} /></PrivateRoute>} />

              <Route path="*" element={<PrivateRoute><PageNotFound/></PrivateRoute>} />

            </Routes>
           
            </div>
            <Footer/>
          </Router>
          
        </Suspense>

        
    </div>
  );
}

export default App;
