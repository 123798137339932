import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <div className="footer">
        <div className="footer-container">
      <div className="footer-col">
        <img alt='happmatch mini logo' src='/icons/happmatch.png' width="30px"/>
        <div>© 2025 HappMatch Inc.</div>
        <div>support@happmatch.com</div>

      </div>
      <div className="footer-col">
        
        <div className='footer-header'>Product</div>
        <Link className='no-decoration' target="_blank" to={"https://blog.happmatch.com/"}>Blog</Link>
        <Link className='no-decoration' target="_blank" to={"https://app.theneo.io/df2425f9-5d5f-40c8-be4e-b5799bcb2fa3/happmatch/happmatch-partner-app-api"}>Developer docs</Link>
        <Link className='no-decoration' target="_blank" to={"https://happmatch.tawk.help/"}> Support</Link>
      </div>
      <div className="footer-col">
      <div className='footer-header'>Company</div>
        <Link className='no-decoration' target="_blank" to={"https://happmatch.com/policies/privacypolicy"}>Privacy policy</Link>
        <Link className='no-decoration' target="_blank" to={"https://happmatch.com/policies/disclaimer"}>Disclaimer</Link>
        <Link className='no-decoration' target="_blank" to={"https://happmatch.com/policies/tos"}>Terms of service</Link>
        <Link className='no-decoration' target="_blank"  to={"https://happmatch.com/policies/cookiepolicy"}>Cookie policy</Link>
      </div>
      <div className="footer-col"></div>
      </div>
    </div>
  );
}
