// context/UserContext.jsx
import { createContext, useContext, useState } from 'react';
import axios from 'axios';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserData = async () => {
    try {
      const res = await axios.get("/user", { withCredentials: true }); // include cookies if needed

      setUser(res.data);

      const setup_paths=['/router','/setup/*']

      if(!res.data.completedOnboarding || !res.data.org.orgSetup)
            if(!setup_paths.includes(window.location.pathname))
                window.location.href='/router'

    
 
      


    } catch (err) {
      console.error("Error fetching user data:", err);
      setUser(null);
    } finally {
    
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, getUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
